import { template as template_1a526c4068644869bf792c8881eede88 } from "@ember/template-compiler";
const FKLabel = template_1a526c4068644869bf792c8881eede88(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
