import { template as template_41f987d9ec8c4066a81e257960fecd20 } from "@ember/template-compiler";
const FKText = template_41f987d9ec8c4066a81e257960fecd20(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
